/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import dataGeojson from '../../../../../../../../../../assets/Transhumance_routes.json';
import Actions from '../../../../../../../../../../reducer/actions';
import nineColorPallete from '../../../../../../../../../../utils/nineColorPallete';
import routesPallete from '../../../../../../../../../../utils/routesPallete';
import ndviPallete from '../../../../../../../../../../utils/ndviPallete';
import rainfallPallete from '../../../../../../../../../../utils/rainfallPallete';
import { LivestockModuleGraph } from './components';
import CountryService from '../../../../../../../../../../services/httpService/countryService';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import './styles.css';
import Translator from '../../../../../../../../../../components/Translator';
import headerImage from '../../../../../../../../../../assets/images/CountryInformationPage/Where/livestockBlack.svg';

const LivestockMap = (props) => {
	const { livestockGeoJson, acledGeoJson, acledPastoralGeoJson } = props;
	const country = useSelector((state) => state.country);
	const dispatch = useDispatch();
	const [map, setMap] = useState(null);
	const routeClass = useSelector((state) => state.routeClass);
	const setRouteClass = (payload) => dispatch({ type: Actions.SetRouteClass, payload });
	const conflictLayersClass = useSelector((state) => state.conflictLayersClass);
	const setConflictLayersClass = (payload) => dispatch({ type: Actions.SetConflictLayersClass, payload });
	const tileLayersClass = useSelector((state) => state.tileLayersClass);
	const setTileLayersClass = (payload) => dispatch({ type: Actions.SetTileLayersClass, payload });
	const setMinDate = (payload) => dispatch({ type: Actions.SetMinDate, payload });
	const setMaxDate = (payload) => dispatch({ type: Actions.SetMaxDate, payload });
	const tileLayersRainfallClass = useSelector((state) => state.tileLayersRainfallClass);
	const setTileLayersRainfallClass = (payload) => dispatch({ type: Actions.SetTileLayersRainfallClass, payload });
	const sliderValue = useSelector((state) => state.sliderValue);
	const [closeLegend, setCloseLegend] = useState(false);
	const [renderLayer, setRenderLayer] = useState(false);
	// const [routesLayer, setRoutesLayer] = useState(null);

	const [toggleLivestockGraph, setToggleLivestockGraph] = useState(false);
	const [livestockGraphData, setLivestockGraphData] = useState(null);
	const [displayChart, setDisplayChart] = useState(false);
	const [selectedFeature, setSelectedFeature] = useState(null);
	const setSliderValue = (payload) => dispatch({ type: Actions.SetSliderValue, payload });

	const routesLayerRef = useRef(null);

	const mapRef = (element) => {
		setMap(element);
	};

	const colorRoutes = (route) => {
		switch (route) {
		case 'Major functional': return '#f1eef6';
		case 'Major functional seasonal': return '#d7b5d8';
		case 'Minor functional': return '#df65b0';
		case 'Minor non-functional': return '#dd1c77';
		case 'Major non-functional': return '#980043';
		case 'Other': return '#ffffcc';
		default: return 'white';
		}
	};

	const setMinMaxDateValues = (geoJson) => {
		if (geoJson) {
			const dateList = (geoJson.features.map((item) => item.properties.event_date));

			const dates = dateList.map((item) => {
				const date = new Date(item);
				const timestamp = date.getTime();
				return timestamp;
			});
			const min = Math.min(...dates);
			const max = Math.max(...dates);
			setMinDate(min);
			setMaxDate(max);
			setSliderValue([min, max]);
		}
	};

	const filterFeatures = (geoJson) => {
		const features = geoJson.features.filter((item) => {
			const date = new Date(item.properties.event_date).getTime();
			// console.log(date);
			if (!sliderValue[0] && !sliderValue[1]) {
				return true;
			}
			if (date >= sliderValue[0] && date <= sliderValue[1]) {
				return true;
			}
			return false;
		});
		return features;
	};

	const adjustStrokeWidth = (zoomLevel) => {
		let strokeWidth;
		if (zoomLevel > 14) {
			strokeWidth = 7;
		} else if (zoomLevel > 12) {
			strokeWidth = 6;
		} else if (zoomLevel > 8) {
			strokeWidth = 5;
		} else if (zoomLevel > 6) {
			strokeWidth = 2;
		} else {
			strokeWidth = 2;
		}
		return strokeWidth;
	};

	useEffect(() => {
		if (!map) return;

		const handleZoomEnd = () => {
			const zoomLevel = map.getZoom();
			const strokeWidth = adjustStrokeWidth(zoomLevel);
			routesLayerRef.current.setStyle({ weight: strokeWidth });
		};

		map.on('zoomend', handleZoomEnd);

		return () => {
			map.off('zoomend', handleZoomEnd);
		};
	}, [map]);

	// console.log(acledGeoJson, acledPastoralGeoJson);

	const updateRoutesLayer = () => {
		if (!map) return;

		map.eachLayer((layer) => {
			if (layer.options.id === 'routes') {
				map.removeLayer(layer);
			}
		});

		const zoomLevel = map.getZoom();
		const strokeWidth = adjustStrokeWidth(zoomLevel);

		const routesLayer = L.geoJson(livestockGeoJson.features, {
			id: 'routes',
			style: (feature) => {
				if (routeClass.length === 0) {
					return { color: colorRoutes(feature.properties.CLASS), opacity: '1', weight: strokeWidth };
				}
				if (routeClass.find((item) => item.featureClass === feature.properties.CLASS)) {
					return { color: colorRoutes(feature.properties.CLASS), opacity: '1', weight: strokeWidth };
				}
				return { color: 'transparent', opacity: '0' };
			},
		});

		routesLayerRef.current = routesLayer;
		map.addLayer(routesLayer);
	};

	const getLivestockData = (item) => {
		CountryService.getLivestockGraphData(`${item.geometry.coordinates[1]},${item.geometry.coordinates[0]}`)
			.then((res) => {
				setToggleLivestockGraph(true);
				setLivestockGraphData(res);
			});
	};

	useEffect(() => {
		updateRoutesLayer();
	}, [updateRoutesLayer]);

	useEffect(() => {
		if (conflictLayersClass) {
			if (conflictLayersClass.label === 'All Events') {
				setMinMaxDateValues(acledGeoJson);
			} else if (conflictLayersClass.label === 'Pastoral-related events') {
				setMinMaxDateValues(acledPastoralGeoJson);
			}
		}
	}, [conflictLayersClass]);

	useEffect(
		() => {
			if (!map) return;
			if (conflictLayersClass.featureClass === null) {
				map.eachLayer((layer) => {
					if (layer.options.id === 'conflict-all' || layer.options.id === 'conflict-pastoral') {
						map.removeLayer(layer);
					}
				});
			}
			if (conflictLayersClass) {
				if (conflictLayersClass.label === 'All Events') {
					map.eachLayer((layer) => {
						console.log(layer);
						if (layer.options.id === 'conflict-pastoral') {
							map.removeLayer(layer);
						}
					});

					// const customClusterIcon = L.divIcon({
					// 	className: 'custom-cluster-icon',
					// });

					const customIcon = L.divIcon({
						html: '<i class="fa-light fa-location-xmark"></i>',
						className: 'myIcon',
						iconAnchor: [15, 40],
						iconSize: [30, 40],
					});

					if (acledGeoJson) {
						const filteredFeatures = filterFeatures(acledGeoJson);
						console.log(filteredFeatures);
						const markers = L.markerClusterGroup({
							id: 'conflict-all',
							iconCreateFunction(cluster) {
								const count = cluster.getChildCount();
								return L.divIcon({
									className: 'custom-cluster-icon',
									html: `<div class="cluster-icon-content">${count}</div>`,
								});
							},
						});
							// const markers = L.markerClusterGroup({ id: 'conflict-all' });
						map.eachLayer((layer) => {
							if (layer.options.id === 'conflict-all') {
								map.removeLayer(layer);
							}
						});
						filteredFeatures.map((item, index) => {
							const temp = L.marker(item.geometry.coordinates, { id: index, icon: customIcon });
							temp.on('click', () => {
								getLivestockData(item);
								setSelectedFeature(item);
							});
							temp.on('mouseover', (feature) => {
								map.eachLayer((l) => {
									if (l.options.id === 'circle') {
										map.removeLayer(l);
									}
								});
								L.circle(item.geometry.coordinates, {
									style: { opacity: 0, width: 1, color: '#000000' },
									id: 'circle',
									radius: 10000,
								}).addTo(map);
							});
							markers.addLayer(temp);
						});

						map.addLayer(markers);

						// }, 1000);
					}
				} else if (conflictLayersClass.label === 'Pastoral-related events') {
					map.eachLayer((layer) => {
						if (layer.options.id === 'conflict-all') {
							map.removeLayer(layer);
						}
					});

					const customIcon = L.divIcon({
						html: '<i class="fa-light fa-location-xmark"></i>',
						className: 'myIcon',
						iconAnchor: [15, 40],
						iconSize: [30, 40],
					});

					if (acledPastoralGeoJson) {
						// clearTimeout(timer);

						// timer = setTimeout(() => {
						const filteredFeatures = filterFeatures(acledPastoralGeoJson);
						const markers = L.markerClusterGroup({
							id: 'conflict-pastoral',
							iconCreateFunction(cluster) {
								const count = cluster.getChildCount(); // Get the number of markers in the cluster
								return L.divIcon({
									className: 'custom-cluster-icon',
									html: `<div class="cluster-icon-content">${count}</div>`, // Include the count in the icon content
								});
							},

						});
						map.eachLayer((layer) => {
							if (layer.options.id === 'conflict-pastoral') {
								map.removeLayer(layer);
							}
						});
						filteredFeatures.map((item, index) => {
							const temp = L.marker(item.geometry.coordinates, { id: index, icon: customIcon });
							temp.on('click', () => {
								getLivestockData(item);
								setSelectedFeature(item);
							});
							temp.on('mouseover', (feature) => {
								map.eachLayer((l) => {
									if (l.options.id === 'circle') {
										map.removeLayer(l);
									}
								});
								L.circle(item.geometry.coordinates, {
									style: { opacity: 0, width: 1, color: '#000000' },
									id: 'circle',
									radius: 10000,
								}).addTo(map);
							});
							markers.addLayer(temp);
						});

						map.addLayer(markers);

						// }, 1000);
					}
				}
				// setConflictLayersClass([]);
				// setRouteClass([]);
			}
		}, [conflictLayersClass, map, sliderValue]
	);

	useEffect(
		() => {
			if (!map) return;
			map.eachLayer((layer) => {
				if (layer.options.id === 'tiles' || layer.options.id === 'tiles-rainfall') {
					map.removeLayer(layer);
				}
			});
			tileLayersClass.map((item) => {
				if (item.category === 'ndvi') {
					console.log(item);
					// eslint-disable-next-line new-cap
					const temp = new L.tileLayer(`${item.url}colormap=greens`, {
						id: 'tiles',
						minZoom: 1,
						maxZoom: 15,
						opacity: 0.25,
					});
					map.addLayer(temp);
					setTileLayersRainfallClass([]);
				}
			});
		}, [tileLayersClass, map]
	);

	useEffect(
		() => {
			if (!map) return;
			map.eachLayer((layer) => {
				if (layer.options.id === 'tiles' || layer.options.id === 'tiles-rainfall') {
					map.removeLayer(layer);
				}
			});
			tileLayersRainfallClass.map((item) => {
				if (item.category === 'rainfall') {
					// eslint-disable-next-line new-cap
					const temp = new L.tileLayer(`${item.url}colormap=blues`, {
						id: 'tiles-rainfall',
						minZoom: 1,
						maxZoom: 15,
						opacity: 0.25,
					});
					map.addLayer(temp);
					setTileLayersClass([]);
				}
			});
		}, [tileLayersRainfallClass, map]
	);

	const boundsLayer = L.geoJSON(livestockGeoJson, {
		pane: `pane-0`,
		id: 0,
		style: {
			fillOpacity: 0,
			weight: 1,
			color: 'white',
		},
	});

	useEffect(
		() => {
			if (!map) return;

			map.createPane(`pane-0`);
			map.getPane(`pane-0`).style.zIndex = 401;
			map.createPane(`pane-1`);
			map.getPane(`pane-1`).style.zIndex = 498;
			map.fitBounds(boundsLayer.getBounds());
			map.setMaxBounds(boundsLayer.getBounds());
			// map.fitBounds(boundsLayers.getBounds());
			// map.setMaxBounds(boundsLayers.getBounds());
			map.zoomControl.setPosition('topleft');
			map.options.maxZoom = 14;
			map.options.minZoom = 6;
			map.options.zoomSnap = 0.25;
			map.options.zoomDelta = 0.25;
			map.options.wheelPxPerZoomLevel = 170;
			// map.setZoom(6);
			// map.addLayer(boundsLayer);
		}, [map]
	);

	const renderLegendRoutes = () => {
		// eslint-disable-next-line no-shadow
		const colorRoutes = routesPallete;
		const squares = [];
		let index = 0;
		while (index != colorRoutes.length - 1) {
			squares.push(<div className="square-size" style={{ background: colorRoutes[index] }} />);
			index += 3;
			if (index > colorRoutes.length - 1) {
				index -= (colorRoutes.length - 1);
			}
		}
		squares.push(<div className="square-size" style={{ background: colorRoutes[colorRoutes.length - 1] }} />);
		return squares;
	};

	const renderLegendNdvi = () => {
		// eslint-disable-next-line no-shadow
		const colorNdvi = ndviPallete;

		const squares2 = [];

		let index = 0;

		while (index != colorNdvi.length - 1) {
			squares2.push(<div className="square-size" style={{ background: colorNdvi[index] }} />);
			index += 3;
			if (index > colorNdvi.length - 1) {
				index -= (colorNdvi.length - 1);
			}
		}

		squares2.push(<div className="square-size" style={{ background: colorNdvi[colorNdvi.length - 1] }} />);
		return squares2;
	};

	const renderLegendRainfall = () => {
		// eslint-disable-next-line no-shadow
		const colorRainfall = rainfallPallete;

		const squares2 = [];

		let index = 0;

		while (index != colorRainfall.length - 1) {
			squares2.push(<div className="square-size" style={{ background: colorRainfall[index] }} />);
			index += 3;
			if (index > colorRainfall.length - 1) {
				index -= (colorRainfall.length - 1);
			}
		}

		squares2.push(<div className="square-size" style={{ background: colorRainfall[colorRainfall.length - 1] }} />);
		return squares2;
	};

	const renderLegendOther = () => {
		const squares2 = <div className="square-size" style={{ background: '#ffffcc' }} />;
		
		return squares2;
	};

	const resetZoom = () => {
		map.fitBounds(boundsLayer.getBounds());
	};

	const headerTemplate = () => {
		return (
			<div className="megapixel-details-header">
				<img src={headerImage} />
				<p><Translator text="Livestock Detail" /></p>
			</div>
		);
	};

	const titleTemplateAdmin = (data, field) => {
		return (
			<p><Translator text={data[field]} /></p>
		);
	};

	return (
		<div className="map-component">
			<MapContainer scrollWheelZoom ref={mapRef}>
				<TileLayer
					attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
					url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}"
				/>
				<div className="reset-zoom" role="button" tabIndex="0" aria-label="reset-zoom" onClick={resetZoom}>
					<i className="fa-duotone fa-house" />
				</div>
			</MapContainer>
			<Dialog
				transitionOptions={{ timeout: 0 }}
				onShow={() => {
					setDisplayChart(true);
				}}
				header={headerTemplate}
				visible={toggleLivestockGraph}
				style={{ width: '90vw' }}
				onHide={() => { setToggleLivestockGraph(false); setDisplayChart(false); }}
				className="livestock-dialog"
			>
				{
					displayChart
						? (
							<>
								<DataTable value={[selectedFeature.properties]} responsiveLayout="scroll" stripedRows>
									<Column body={(data) => titleTemplateAdmin(data, 'admin1')} header={<Translator text="Admin 1" />} />
									<Column body={(data) => titleTemplateAdmin(data, 'admin2')} header={<Translator text="Admin 2" />} />
									<Column body={(data) => titleTemplateAdmin(data, 'admin3')} header={<Translator text="Admin 3" />} />
								</DataTable>
								<LivestockModuleGraph data={livestockGraphData?.rainfallConflict} id="rainfall" label="Rainfall" />
								<LivestockModuleGraph data={livestockGraphData?.ndviConflict} id="ndvi" label="NDVI" />
							</>
						)
						: null
				}
			</Dialog>
			{!closeLegend
				? (
					<div className="map-legend">
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
							{/* <h2>Legend</h2> */}
							{/* <Button onClick={() => setCloseLegend(true)}>	<i className="fa-solid fa-xmark" /></Button> */}
							<div style={{ display: 'flex', cursor: 'pointer' }} role="button" tabIndex="0" aria-label="close" onClick={() => setCloseLegend(true)}>
								<i className="fa-solid fa-xmark" />
							</div>
						</div>
						<div className="color-pallete-wrap">
							{/* <p><Translator text="Limited Conflict" /> </p> */}
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<p className="heading-legend"><Translator text="Transhumance Routes" /></p>
								<div className="legends">
									<p><Translator text="Major Functional" /></p>
									{renderLegendRoutes()}
									<p><Translator text="Major Non-Functional" /></p>
									<p className="other"><Translator text="Other" /></p>
									{renderLegendOther()}
								</div>
								<p className="heading-legend"><Translator text="Vegetation Layers (NDVI)" /></p>
								<div className="legends">
									<p>0</p>
									{renderLegendNdvi()}
									<p>0.4</p>
								</div>
								<p className="heading-legend"><Translator text="Rainfall Layers" /></p>
								<div className="legends" style={{ display: 'flex', flexDirection: 'row' }}>
									<p style={{ marginRight: '15px' }}>0</p>
									{renderLegendRainfall()}
									<p style={{ marginLeft: '15px' }}>500</p>
								</div>
							</div>
						</div>
						{/* <div className="download-button"> */}
						{/*	<Button label={<Translator text="Download Data" />} icon="fa-regular fa-down-to-bracket" onClick={downloadData} /> */}
						{/* </div> */}
					</div>
				)
				: (
					<div className="open-legend-btn" role="button" tabIndex="0" aria-label="info" onClick={() => setCloseLegend(false)}>
						<i className="fa-light fa-circle-info" />
					</div>

				)

			}

			{/* <div className="reset-zoom" role="button" tabIndex="0" aria-label="reset-zoom" onClick={resetZoom}> */}
			{/*	<i className="fa-duotone fa-house" /> */}
			{/* </div> */}
		</div>
	);
};
export default LivestockMap;
