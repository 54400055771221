import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Map } from './components';
import CountryService from '../../../../../../services/httpService/countryService';
import headerImage from '../../../../../../assets/images/CountryInformationPage/Who/Dialog/header.svg';
import { backgroundColors as defaultColorsKenya } from '../../../../../../utils/palleteKenya';
import { backgroundColors as defaultColorsSenegal } from '../../../../../../utils/palleteSenegal';
import { backgroundColors as defaultColorsZimbabwe } from '../../../../../../utils/palleteZimbabwe';
import { backgroundColors as defaultColorsUganda } from '../../../../../../utils/palleteUganda';
import { backgroundColors as defaultColorsNigeria } from '../../../../../../utils/palleteNigeria';
import { backgroundColors as defaultColorsGuatemala } from '../../../../../../utils/palleteGuatemala';
import { backgroundColors as defaultColorsMali } from '../../../../../../utils/palleteMali';
import { backgroundColors as defaultColorsPhilippines } from '../../../../../../utils/palletePhilipines';
import { backgroundColors as defaultColorsZambia } from '../../../../../../utils/palleteZambia';
import { backgroundColors as defaultColorsEthiopia } from '../../../../../../utils/palleteEthiopia';
import { backgroundColors as defaultColorsSudan } from '../../../../../../utils/palleteSudan';
import { backgroundColors as defaultColorsBurkina } from '../../../../../../utils/palleteBurkinaFaso';
import { backgroundColors as defaultColorsNiger } from '../../../../../../utils/palleteNiger';
import './styles.css';
import Actions from '../../../../../../reducer/actions';
import Translator from '../../../../../../components/Translator';

const Who = () => {
	const dispatch = useDispatch();

	const whoSelectedFeature = useSelector((state) => state.whoSelectedFeature);

	const megapixelsGeoJson = useSelector((state) => state.megapixelsGeoJson);

	const country = useSelector((state) => state.country);
	const setWhoSelectedFeature = (payload) => dispatch({ type: Actions.SetWhoSelectedFeature, payload });

	const [toggleDialog, setToggleDialog] = useState(false);
	const [dialogAdminTableData, setDialogAdminTableData] = useState([]);
	const [dialogData, setDialogData] = useState([]);
	const [nationalAverages, setNationalAverages] = useState(null);

	const defaultColors = (filters) => {
		switch (country.id) {
		case 'KEN': return defaultColorsKenya(filters);
		case 'SEN': return defaultColorsSenegal(filters);
		case 'ZWE': return defaultColorsZimbabwe(filters);
		case 'UGA': return defaultColorsUganda(filters);
		case 'NGA': return defaultColorsNigeria(filters);
		case 'GTM': return defaultColorsGuatemala(filters);
		case 'MLI': return defaultColorsMali(filters);
		case 'PHL': return defaultColorsPhilippines(filters);
		case 'ZMB': return defaultColorsZambia(filters);
		case 'ETH': return defaultColorsEthiopia(filters);
		case 'SDN': return defaultColorsSudan(filters);
		case 'BFA': return defaultColorsBurkina(filters);
		case 'NER': return defaultColorsNiger(filters);
		default: return defaultColorsKenya(filters);
		}
	};

	const getDecimals = (value) => {
		if (typeof value === 'number') {
			return value.toFixed(2);
		} return 'N/A';
	};

	useEffect(
		() => {
			CountryService.getNationalAverages(country.id)
				.then((res) => {
					console.log(res);
					setNationalAverages(res.data_info);
				});
			return () => setWhoSelectedFeature({});
		}, []
	);

	useEffect(
		() => {
			if (whoSelectedFeature.id && nationalAverages) {
				setDialogAdminTableData([{ admin1: whoSelectedFeature.properties.NAME_1?.replaceAll(';', ' • '), admin2: whoSelectedFeature.properties.NAME_2?.replaceAll(';', ' • '), admin3: whoSelectedFeature.properties.NAME_3?.replaceAll(';', ' • ') }]);
				const temp = [
					{ indicator: 'Population', description: <Translator text="Population density" />, type: 'Number of People', value: whoSelectedFeature.properties.pop, average: '' },
					{ indicator: 'Years of education female', description: 'Average number of years of female education', type: 'Average number of years', value: getDecimals(whoSelectedFeature.properties.female_ed), average: getDecimals(nationalAverages.female_ed) },
					{ indicator: 'Years of education male', description: 'Average number of years of male education found', type: 'Average number of years', value: getDecimals(whoSelectedFeature.properties.male_ed), average: getDecimals(nationalAverages.male_ed) },
					{ indicator: 'Stunting prevalence (under 5 years)', description: 'The prevalence of stunting in children under five is the proportion of children with a height-for-age, weight-for-height, or weight-for-age z-score, respectively, that is more than two standard deviations below the World Health Organization’s median growth reference standards for a healthy population.', type: 'Percentage', value: `${getDecimals(whoSelectedFeature.properties.stunting)} `, average: getDecimals(nationalAverages.stunting) },
					{ indicator: 'Wasting prevalence (under 5 years)', description: 'The prevalence of wasting in children under five is the proportion of children with a height-for-age, weight-for-height, or weight-for-age z-score, respectively, that is more than two standard deviations below the World Health Organization’s median growth reference standards for a healthy population.', type: 'Percentage', value: `${getDecimals(whoSelectedFeature.properties.wasting)} `, average: getDecimals(nationalAverages.wasting) },
					{ indicator: 'Underweight prevalence (under 5 years)', description: 'The prevalence of underweight in children under five is the proportion of children with a height-for-age, weight-for-height, or weight-for-age z-score, respectively, that is more than two standard deviations below the World Health Organization’s median growth reference standards for a healthy population.', type: 'Percentage', value: `${getDecimals(whoSelectedFeature.properties.underweight)}`, average: '' },
					{ indicator: 'Nightlights', description: 'Proxy for estimating income inequality based on satellite images of nighttime lights', type: 'Average reflectance value', value: getDecimals(whoSelectedFeature.properties.nightlights), average: getDecimals(nationalAverages.nightlights) },
					{ indicator: 'Piped water', description: 'Proportion of population having access to piped water', type: 'Percentage', value: `${getDecimals(whoSelectedFeature.properties.piped_water)}`, average: getDecimals(nationalAverages.piped_water) },
					{ indicator: 'Sanitation facilities', description: 'Proportion of population having access to sanitation facilities', type: 'Percentage', value: `${getDecimals(whoSelectedFeature.properties.sanitation)}`, average: getDecimals(nationalAverages.sanitation) },
					{ indicator: 'Estimated Net Migration', description: 'Estimates of net migration (in-migrants minus out-migrants) during the 2000 decade', type: 'Average number of people', value: getDecimals(whoSelectedFeature.properties.migration), average: getDecimals(nationalAverages.migration) },
					{ indicator: 'Absolute wealth index', description: 'Average Absolute Wealth Index predicting the relative standard of living within countries', type: 'Average index', value: getDecimals(whoSelectedFeature.properties.awe), average: getDecimals(nationalAverages.awe) },
					{ indicator: 'Relative wealth index', description: 'Average Relative Wealth Index predicting the relative standard of living within countries', type: 'Average index', value: getDecimals(whoSelectedFeature.properties.rwi), average: getDecimals(nationalAverages.rwi) },
					{ indicator: 'Dependency Ratio', description: 'Average dependency ratio. The dependency ration describes the ratio between those of working age and those young and old who are dependent upon them. ', type: 'Average ratio', value: getDecimals(whoSelectedFeature.properties.dep_ratio), average: getDecimals(nationalAverages.dep_ratio) },
					{ indicator: 'Difference of years of education (male - female)', description: 'Average difference between the number of years of male and female education', type: 'Average number of years', value: getDecimals(whoSelectedFeature.properties.median_male_female_edu_diff), average: getDecimals(nationalAverages.median_male_female_edu_diff) },
					{ indicator: 'Livelihood zones', description: 'Majority of FEWSNET livelihood zones categories. Livelihood zone maps define geographic areas of a country where people generally share similar options for obtaining food and income and similar access to markets.', type: '', value: whoSelectedFeature.properties.livelihoods ? whoSelectedFeature.properties.livelihoods.replaceAll(';', ' • ') : 'N/A', average: '' },
					{ indicator: 'Food security', description: 'Majority of food security classes. FEWS NET food security classification data, using the Integrated Food Security Phase Classification (IPC)', type: '', value: '', average: '' },
				];
				setDialogData(temp);
				setToggleDialog(true);
			}
		}, [whoSelectedFeature]
	);

	const headerTemplate = () => {
		return (
			<div className="megapixel-details-header">
				<img src={headerImage} />
				<p><Translator text="Mega Pixel Details" /></p>
			</div>
		);
	};

	const renderImpactPathways = () => {
		if (!whoSelectedFeature.properties.ip_all_category) return null;
		const temp = whoSelectedFeature.properties.ip_all_category.split(';');
		return temp.map((item) => {
			if (item.includes('Inequality') || item.includes('Resources scarcity') || item.includes('Migration') || item.includes('Low Productivity') || item.includes('Undernutrition')) {
				return (
					<div className="indicator">
						<Translator text={item} />
					</div>
				);
			} return null;
		});
	};
	const indicatorTemplate = (data, index) => {
		return (
			<div className="indicator-template">
				{
					data.indicator ? (
						<p className="open-sans"><Translator text={data.indicator} /></p>
					)
						: <p className="open-sans">N/A</p>

				}

				<Tooltip target={`.info-icon-${index.rowIndex}`} content={`${data.description}`} />
				<i className={`info-icon-${index.rowIndex} fa-light fa-circle-info info-icon`} />
			</div>
		);
	};

	const template = (data) => {
		return (
			<p className="open-sans">{data || 'N/A'}</p>
		);
	};

	const typeTemplate = (data) => {
		if (data.type) {
			return (
				<p className="open-sans"><Translator text={data.type} /></p>
			);
		}
		return (
			<p><Translator text="N/A" /></p>
		);
	};

	const valueTemplate = (data) => {
		if (data.value) {
			return (
				<p className="open-sans">{data.value}</p>
			);
		}
		return (
			<p><Translator text="N/A" /></p>
		);
	};

	const averageTemplate = (data) => {
		if (data.average) {
			return (
				<p className="open-sans">{data.average}</p>
			);
		}
		return (
			<p><Translator text="N/A" /></p>
		);
	};

	const titleTemplateAdmin1 = (data) => {
		return (
			<p><Translator text={data.admin1} /></p>
		);
	};

	const titleTemplateAdmin2 = (data) => {
		return (
			<p><Translator text={data.admin2} /></p>
		);
	};

	const titleTemplateAdmin3 = (data) => {
		return (
			<p><Translator text={data.admin3} /></p>
		);
	};

	return (
		<div className="who" style={{ position: 'relative' }}>
			{megapixelsGeoJson ? <Map /> : null}
			{whoSelectedFeature.properties
				? (
					<Dialog className="megapixel-details" header={headerTemplate} visible={toggleDialog} style={{ width: '80vw' }} onHide={() => setToggleDialog(false)}>
						<div className="megapixel-details-content">
							<DataTable value={dialogAdminTableData} responsiveLayout="scroll" stripedRows>
								<Column field="admin1" body={titleTemplateAdmin1} header={<Translator text="Admin 1" />} />
								<Column field="admin2" body={titleTemplateAdmin2} header={<Translator text="Admin 2" />} />
								<Column field="admin3" body={titleTemplateAdmin3} header={<Translator text="Admin 3" />} />
							</DataTable>
							<div className="indicators">
								<div className="indicator" style={defaultColors(whoSelectedFeature.properties.conflict_clust_label)}>
									<Translator text={whoSelectedFeature.properties.conflict_clust_label} />
								</div>
								<div className="indicator" style={defaultColors(whoSelectedFeature.properties.clim_cluster_short_label)}>
									<Translator text={whoSelectedFeature.properties.clim_cluster_short_label} />
								</div>
								{renderImpactPathways()}
							</div>
							<p className="sub-title"><Translator text="Population Data" /></p>
							<DataTable value={dialogData} responsiveLayout="scroll" stripedRows>
								<Column field="indicator" body={(data, index) => indicatorTemplate(data, index)} header={<Translator text="Indicator" />} />
								<Column field="type" body={typeTemplate} header={<Translator text="Type / Unit" />} />
								<Column field="value" body={valueTemplate} header={<Translator text="Mega Pixel Value" />} />
								<Column field="average" body={averageTemplate} header={<Translator text="National Average" />} />
							</DataTable>
							{/* <p className="open-sans"> */}
							{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor */}
							{/*	incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis */}
							{/*	nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. */}
							{/*	Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore */}
							{/*	eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt */}
							{/*	in culpa qui officia deserunt mollit anim id est laborum. */}
							{/* </p> */}
						</div>
					</Dialog>
				)
				: null
			}
		</div>
	);
};

export default Who;
